import React from 'react'
import PropTypes from 'prop-types'
import Socials from '../Socials'

const Footer = ({ copyright }) => {
  return (
    <footer className='pa4 pa5-l black-70 bt b--black-10 mw8 center pv5'>
      <div className='dt dt--fixed w-100'>
        <div className='dn tc dtc-ns v-mid'>
          <p className='f5 black-70 dib pr3 mb3'>
            {copyright} <a className='link black hover-purple' href='/privacy-policy' target='_blank' rel='noreferrer nofollow noopener'>Privacy Policy</a>
            <span className="p1"> | </span>
            <a className='link black hover-purple' href='/contact' target='_blank' rel='noreferrer nofollow noopener'>Contact Us</a>
          </p>
        </div>
        {/* <Socials /> */}
      </div>
    
    </footer>
  )
}

Footer.propTypes = {
  copyright: PropTypes.string,
}

export default Footer
