import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import './styles.scss'
const config = require('../../../config')

const Header = ({ siteTitle }) => (
  <header className="bg-white tl center mw8 pv2 ph2 avenir borderBottomLine">
    <div className="flex justify-between">
    <Link className='f6 f5-l link bg-animate black-80 dib' to='/'>
      <div className="flex-container">
          <div className="column"><img className="logo" src={config.siteLogo} /> </div>
          <div className="column bg-alt">
            <h1 className='logo-text mt2 mb0 baskerville fw1 f2'>{siteTitle}</h1>
          </div>
      </div>
      </Link>

      <div className="w-30 mr2">
        <nav className='tc mw8 center'>
          <Link className='f6 f5-l link bg-animate black-80 dib pa3 ph4-l' to='/search'>Search</Link>
        </nav>
      </div>
    </div>

  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

export default Header
